<template>
  <div class="page-container-table">
    <!-- ==========================================================Bắt đầu header-------------------------------- -->
    <div class="d-flex justify-content-between">
      <div>
        <!--=============== nút Xóa file trên cùng================= -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="btn-icon ml-2 mb-1"
          :disabled="!showBtnMultiDelete"
          @click="deleteMore()"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          for="file"
          type="file"
          variant="primary"
          class="btn-icon mb-1 ml-1"
          :disabled="!showBtnMultiDelete"
          @click="restoreMore()"
        >
          <feather-icon
            icon="RotateCcwIcon"
            for="file"
            class="input_label"
          />
        </b-button>
      </div>
      <!--===========================Kết thúc phần nút trên cùng bên trái================= -->
      <!--========================== Bắt đầu phần tìm kiếm trên cùng bên phải================= -->
      <div>
        <!--========================================= Ô tìm kiếm ================================= -->
        <search
          class="search-input mb-1 mr-2"
          @change="search"
        />
      </div>
    </div>
    <!-- ==========================================================Phần bảng-------------------------------- -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        setCurrentPage: urlQuery.pageNumber,
        perPage: urlQuery.pageSize,
      }"
      :sort-options="{
        enabled: false ,
        initialSortBy: [
          {field: 'name', type: 'asc'},
          {field: 'code', type: 'desc'}
        ],
      }"
      :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      @on-selected-rows-change="selectRowTable"
    >
      <!--=================== chỉnh sửa phần chức năng trong bảng==================== -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'cn'">
          <span>
            <feather-icon
              icon="EyeIcon"
              class="table__chucnang__icon"
              size="18"
              style="margin-right: 14px"
              @click="handleDetailUser(props.row.id)"
            /><feather-icon
              icon="RotateCcwIcon"
              size="18"
              class="table__chucnang__icon"
              @click="handleClickRestoreButton(props.row.id)"
            /><feather-icon
              icon="Trash2Icon"
              size="18"
              class="table__chucnang__icon"
              style="margin-left: 14px"
              @click="handleClickDeleteButton(props.row.id)"
            /></span>
        </span>
        <span v-if="props.column.field === 'userDelete'">
          <span class="text-nowrap">{{ props.row.userDelete }}</span>
        </span>
        <span v-if="props.column.field === 'groupUserName'">
          <span class="text-nowrap">{{ props.row.groupUserName }}</span>
        </span>
        <span v-if="props.column.field === 'dateDelete'">
          <span class="text-nowrap">{{ props.row.dateDelete | formatDateToDDMM }}</span>
        </span>
      </template>
      />
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord || 0"
      :currentPage="currentPage || 0"
      @pageClick="handlePageClick"
    />
    <!-- ==========================================================Kết thúc header-------------------------------- -->
    <confirm-modal
      :id="confirmModalId"
      :title="titleCustom"
      :content="modalContent"
      @accept="restoreToTrash"
    />
    <modal-detail-group-user
      :id="modalDetailGroupUser"
      :idDetailGroup="idDetailGroup"
      @callAPI="fetchdata()"
    />

    <!-- ========================================================== kết thúc Phần bảng-------------------------------- -->
  </div>
</template>

<script>
// =====================================================Phân import file ======================================//
import {
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown, BDropdownItem, BDropdownDivider, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { formatDateToDDMM } from '@core/utils/filter'
import MyPagination from '@/components/pagination/MyPagination.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalDetailGroupUser from '../components/ModalDetailGroupUser.vue'

export default {
  components: {
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // eslint-disable-next-line vue/no-unused-components
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    MyPagination,
    Search,
    ConfirmModal,
    ModalDetailGroupUser,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      idDetailGroup: '',
      modalDetailGroupUser: 'modalDetailGroupUser',
      titleCustom: '',
      selectTable: [],
      showBtnMultiDelete: false,
      confirmModalId: '',
      restoreID: '',
      deleteID: '',
      modalContent: '',
      totalRecord: 5,
      currentPage: 5,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Tài khoản xóa',
          field: 'userDelete',
        },
        {
          label: 'Nhóm người dùng bị xóa',
          field: 'groupUserName',
        },
        {
          label: 'Thời gian xóa',
          field: 'dateDelete',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          tdClass: 'table__chucnang',
          thClass: 'table__chucnang__th',
        },
      ],
      rows: [
      ],
    }
  },
  async created() {
    await this.fetchDatatable()
  },
  methods: {
    ...mapActions('TrashBin', ['doFetchListTrashGroup', 'doRestoreToTrashGroup', 'doDeleteToTrashGroup']),
    // Xử lý khi ấn nút phân trang
    // =================================================Phân lấy dữ liệu bảng ===========================
    async fetchDatatable() {
      const data = await this.doFetchListTrashGroup(this.urlQuery)
      this.rows = data.data.pageLists
      this.totalRecord = data.data.totalRecord
    },
    // ===============================================Phân phân trang ==============================
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDatatable()
    },
    // ==============================================phần seach ============================================
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchDatatable()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.doFetchListUsers()
      }
    },
    // ======================================== ấn nút restore ======================================//
    async handleClickRestoreButton(id) {
      this.modalContent = 'Bạn có muốn khôi phục không!'
      this.titleCustom = 'Khôi phục'
      this.restoreID = [id]
      this.$bvModal.show(this.confirmModalId)
      this.confirmModalId = 1
    },
    // ================-----[ nút khôi phục nhiều ] -----------====================//
    async restoreMore() {
      this.modalContent = 'Bạn có muốn khôi phục các mục đã chọn không!'
      this.titleCustom = 'Khôi phục'
      this.$bvModal.show(this.confirmModalId)
      this.confirmModalId = 2
    },
    // ================-----[ nút khôi phục] -----------====================//
    async restoreToTrash() {
      if (this.confirmModalId === 1) { // khôi phục 1 người dùng
        await this.restoreID
        await this.doRestoreToTrashGroup(this.restoreID)
        await this.fetchDatatable()
        this.$bvToast.toast('Khôi phục thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
      if (this.confirmModalId === 2) { // khôi phục nhiều người dùng
        await this.selectTable
        await this.doRestoreToTrashGroup(this.selectTable)
        await this.fetchDatatable()
        this.$bvToast.toast('Khôi phục thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
      if (this.confirmModalId === 3) { // xóa 1 người dùng
        await this.deleteID
        await this.doDeleteToTrashGroup(this.deleteID)
        await this.fetchDatatable()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
      if (this.confirmModalId === 4) { // xóa nhiều người dùng
        await this.selectTable
        await this.doDeleteToTrashGroup(this.selectTable)
        await this.fetchDatatable()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    //= ==========================================select table ==========================================//
    selectRowTable(val) {
      this.selectTable = val.selectedRows.map(e => e.id)
      // const { selectedRows } = val
      this.showBtnMultiDelete = this.selectTable.length > 0
    },
    // ============================================Ấn nút xóa ===========================================//
    handleClickDeleteButton(id) {
      this.modalContent = 'Bạn có muốn xóa vĩnh viễn không!'
      this.titleCustom = 'Xóa'
      this.deleteID = [id]
      this.confirmModalId = 3
      this.$bvModal.show(this.confirmModalId)
    },
    // ============================================Ấn nút xóa nhiều===========================================//
    async deleteMore() {
      this.modalContent = 'Bạn có muốn xóa vĩnh viễn các mục đã chọn không!'
      this.titleCustom = 'Xóa'
      this.$bvModal.show(this.confirmModalId)
      this.confirmModalId = 4
    },
    // ================= ấn nút chi tiết ========================
    handleDetailUser(id) {
      this.idDetailGroup = id
      this.$bvModal.show(this.modalDetailGroupUser)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
